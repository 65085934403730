













































































































import Vue from "vue";
import {Filtro} from "@/Classes/FiltroClass";
import StruttureService, {StruttureResponse} from "@/services/StruttureService";
import CampiService from "@/services/CampiService";
import PrenotazioneService from "@/services/PrenotazioneService";
import {Component} from "vue-property-decorator";
import Toolbar from "@/components/Toolbar.vue";
import {DateTime} from "luxon";
import ScontiService, {ScontiRequest} from "@/services/ScontiService";
import {PrenotazioneModel} from "@/Classes/PrenotazioneClass";

@Component({
  components: {
    Toolbar,
  },
})
export default class CancelledReservations extends Vue {

  public filtro = new Filtro();
  public listaPrenotazioni: PrenotazioneModel[] =[]
  public dateSelezionate: any = [];
  public listaCampi: any = [];
  public listaStrutture: StruttureResponse = [];
  public search: string = ""
  public page: number = 1
  public pageSize: number = 10

  validation = {
    campo: [{required: false, message: " "}],
    id_struttura: [{required: true, message: "Campo obbligatorio"}],
    data: [{required: false, message: "Campo obbligatorio"}]
  };

  async ricercaPrenotazioni() {
    try {
      const ricercaPrenForm = this.$refs.filtriForm as any;

      ricercaPrenForm.validate(async (valid: boolean) => {
        if (valid) {
          if (this.dateSelezionate.length === 0) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 365)
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            this.filtro.from_date = start;
            this.filtro.to_date = end;
          } else {
            this.filtro.from_date = this.dateSelezionate[0]
            this.filtro.to_date = this.dateSelezionate[1]
          }
          if (this.filtro.id_campo === "")
            this.filtro.id_campo = null;
          const responsePrenotazioni = await PrenotazioneService.getPrenotazioneAnnullate(this.filtro);
          this.listaPrenotazioni=responsePrenotazioni.data

          for (let i=0; i<this.listaPrenotazioni.length; i++){
            this.listaPrenotazioni[i].data_ora = DateTime.fromISO(this.listaPrenotazioni[i].data_ora).toUTC().toFormat("dd/MM/yyyy - HH:mm")
            this.listaPrenotazioni[i].importo = +this.listaPrenotazioni[i].importo / 100
            this.listaPrenotazioni[i].importo_rimborso = +this.listaPrenotazioni[i].importo_rimborso / 100
          }

          const loading = this.$loading({
            lock: true,
          });
          setTimeout(async () => {
            loading.close();
          }, 1000);
        }

      });
    } catch (error: any) {
      this.$message.error(error.message);
    }
  }

  public async RiceviStrutture() {
    try {
      const response = await StruttureService.getStrutture();

      if (response.error) {
        this.$message.error(response.msg)
      } else {
        this.listaStrutture = response.data;
        this.filtro.id_struttura = response.data[0].struttura.info.id

        this.RiceviListaCampi();
        this.ricercaPrenotazioni();
      }
    } catch (err: any) {
      this.$router.push("/error")
    }
  }

  public async RiceviListaCampi() {
    const responseCampi = await CampiService.ListaCampi(this.filtro.id_struttura);
    this.listaCampi = responseCampi.data.map(x => {
      return {
        label: x.nome,
        value: x.id,
        color: x.color
      }
    })
  }

  open(scope: PrenotazioneModel){
    this.$confirm("Il valore del rimborso dipende dal prezzo pagato dall'utente più il valore dell'eventuale sconto applicato",'Vuoi creare un buono sconto di '+scope.importo_rimborso +"€ ?",{
      confirmButtonText: "OK",
      cancelButtonText: "Indietro"
    }).then(() => {
      this.EmettiSconto(scope)
    }).catch(() => {
      this.$message({
        type: 'warning',
        message: 'Lo sconto non è stato creato'
      })
    })
  }

  async EmettiSconto(row: PrenotazioneModel) {
    if (row.pagato) {
      const sconto: ScontiRequest = {
        codice_sconto: "RIMBORSO PRENOTAZIONE DEL: " + row.data_ora,
        valore_sconto: row.importo_rimborso * 100,
        utente: {
          id: row.utente.id
        },
        tipo_sconto_buono: {
          id: 3
        },
        tipo_sconto: {
          id: 2
        },
        max_eventi: 0,
        start_time: DateTime.now().toISODate(),
        expire_time: DateTime.fromSeconds(2524990179).toISODate(),
        sport: null,
        struttura: {
          id: this.filtro.id_struttura
        }
      }
      const response = await ScontiService.PushSconto(sconto)
      if (response.error) {
        this.$message.error(response.msg)
      } else {
        const res = await PrenotazioneService.RimborsoPrenotazione(row.id, this.filtro.id_struttura)
        if(res.error){
          this.$message.error(res.msg)
        } else {
          this.$message.success("Sconto creato con successo");
          window.location.reload()
        }
      }
    }
  }

  public VerificaRimborso(scope: PrenotazioneModel){
    if(!scope.rimborsato && scope.utente != undefined && scope.utente.id != null && scope.pagato){
      return true
    }
    else {
      return false
    }
  }

  pagedTableData(){
    return this.listaPrenotazioni.filter(data => !this.search || data.utente.email.toLowerCase().includes(this.search.toLowerCase()))
        .slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
  }

  pagedTableSearch(){
    this.setPage(1)
    return this.listaPrenotazioni.filter(data => !this.search || data.utente.email.toLowerCase().includes(this.search.toLowerCase()))
  }

  pagedTablePagination(){
    return this.listaPrenotazioni.filter(data => !this.search || data.utente.email.toLowerCase().includes(this.search.toLowerCase()))
  }
  public setPage (val: number) {
    this.page = val
  }

  async mounted() {
    await this.RiceviStrutture();
  }
}
